import { intersection, isEqual, map } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NoResults from "../components/NoResults";
import ReissuedReportsBanner from "../components/ReissuedReportsBanner";
import ReportGroupFilter, { GroupOption } from "../components/ReportGroupFilter";
import ReportList from "../components/ReportList";
import ReportListActions from "../components/ReportListActions";
import ReportListHeading from "../components/ReportListHeading";
import ReportSearch, { getSearchFilter } from "../components/ReportSearch";
import ReportSortToggle from "../components/ReportSortToggle";
import ReportViewToggle from "../components/ReportViewToggle";
import ResultsError from "../components/ResultsError";
import LoadingSpinner from "../components/common/LoadingSpinner";
import PageHeader from "../components/common/PageHeader";
import { CYTED_HEALTH } from "../helpers/strings";
import { AppDispatch, RootState } from "../store";
import { selectUserGroups } from "../store/authSlice";
import { fetchAllReports } from "../store/reportsSlice";
import { Report, ResultCounts } from "../types/report";
import { UserGroup } from "../types/userGroup";

const Home = (): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const userGroups = useSelector(selectUserGroups);
  const { status, searchResults, filterOptions } = useSelector(
    (state: RootState) => state.reports
  );
  const loading: boolean = status === "pending";
  const fetchResultsError: boolean = status === "failed";
  const { textSearch, userGroupId, viewOption, sortOption } = filterOptions;

  // Local state
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const anyReportsSelected: boolean = selectedReports.length > 0;

  // Search results
  const { Inbox, Archive } = searchResults;
  const { reports } = searchResults[viewOption];
  const searchFilter = getSearchFilter(textSearch);
  const filteredReports: Report[] = reports.filter(searchFilter);
  const filteredReportsById: string[] = map(filteredReports, "reportId");
  const filteredResultCounts: ResultCounts = {
    Inbox: Inbox.reports.filter(searchFilter).length,
    Archive: Archive.reports.filter(searchFilter).length,
  };

  // Handle users who belong to multiple user groups
  const isUserInMultipleGroups: boolean = userGroups.length > 1;
  const allUserGroupsOption: UserGroup = {
    id: GroupOption.All,
    name: `All reports (${userGroups.length} groups)`,
  };
  const selectedUserGroupOption: UserGroup | undefined = userGroups.find(
    ({ id }) => id === userGroupId
  );

  // Page title
  const pageTitle = "Results";

  // Page subtitle
  const pageSubtitle = isUserInMultipleGroups
    ? (selectedUserGroupOption?.name ?? allUserGroupsOption.name)
    : userGroups[0].name;

  // Set document title and fetch all reports on page load
  useEffect(() => {
    document.title = `${pageTitle} | ${CYTED_HEALTH}`;
    dispatch(fetchAllReports({ showSpinner: true }));
  }, [dispatch]);

  // Clear report selection when specific filter options change
  useEffect(() => {
    clearSelectedReports();
  }, [userGroupId, viewOption, sortOption]);

  // Deselect reports that are no longer visible after text search change
  useEffect(() => {
    const visibleSelectedReports = intersection(filteredReportsById, selectedReports);
    const shouldDeselectReports = !isEqual(visibleSelectedReports, selectedReports);
    if (shouldDeselectReports) {
      setSelectedReports(visibleSelectedReports);
    }
  }, [textSearch, filteredReportsById, selectedReports]);

  const clearSelectedReports = (): void => {
    setSelectedReports([]);
  };

  const updateSelectedReports = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = e.target;
    const prevState: string[] = selectedReports;
    let newState = [...prevState];
    if (checked) {
      newState = [...prevState, value];
    } else {
      newState.splice(prevState.indexOf(value), 1);
    }
    setSelectedReports(newState);
  };

  return (
    <div className="container is-max-desktop">
      <main className="section pt-0">
        <PageHeader title={pageTitle} subtitle={pageSubtitle} />
        <ReissuedReportsBanner reports={Inbox?.reports} />
        <div className="columns is-variable is-2" role="search">
          <div className="column">
            <ReportSearch />
          </div>
          {isUserInMultipleGroups && (
            <div className="column is-3">
              <ReportGroupFilter
                userGroups={[allUserGroupsOption, ...userGroups]}
                selectedGroup={userGroupId}
              />
            </div>
          )}
          <div className="column is-narrow">
            <ReportViewToggle
              selectedView={viewOption}
              reportCounts={filteredResultCounts}
            />
          </div>
          <div className="column is-narrow">
            <ReportSortToggle selectedSort={sortOption} />
          </div>
        </div>
        <div
          className={`cyt-action-bar ${anyReportsSelected ? "cyt-action-bar--active" : ""}`}
        >
          <div className="column" style={{ minHeight: "3.75rem" }}>
            <ReportListHeading
              currentView={viewOption}
              hideHeading={anyReportsSelected}
            />
            {anyReportsSelected && (
              <ReportListActions
                currentView={viewOption}
                selectedReports={selectedReports}
                clearSelectedReports={clearSelectedReports}
              />
            )}
          </div>
        </div>

        {loading && <LoadingSpinner />}

        {!loading && fetchResultsError && <ResultsError />}

        {!loading &&
          !fetchResultsError &&
          (filteredResultCounts[viewOption] === 0 ? (
            <NoResults
              currentView={viewOption}
              currentSearch={textSearch}
              filteredResultCounts={filteredResultCounts}
            />
          ) : (
            <ReportList
              reports={filteredReports}
              currentSearch={textSearch}
              currentSort={sortOption}
              selectedReports={selectedReports}
              updateSelectedReports={updateSelectedReports}
            />
          ))}
      </main>
    </div>
  );
};

export default Home;
